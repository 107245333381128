import {initFederation} from '@angular-architects/module-federation';
import {environment} from "./environments/environment";

initFederation(environment.moduleFederationManifestFilePath)
  .catch((err) => console.error(err))
  .then((_) => import('./bootstrap'))
  .catch((err) => console.error(err));

if (!environment.production) {
  const favicon: HTMLLinkElement | null = document.querySelector('[rel="icon"]');
  if (favicon) {
    favicon.href = 'favicon-dev.ico';
  }

  let devIndicator = document.querySelector('#devIndicator');

  if (!devIndicator) {
    devIndicator = document.createElement('div');
    devIndicator.id = "dev-indicator";
    document.body.append(devIndicator);
  }
}
